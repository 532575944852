const OFFERS_INFO = [
  {
    title: 'Trumny',
    description: `Zapraszamy do zapoznania się z naszym różnorodnym asortymentem trumien, oferującym bogactwo kolorów i wzorów. Ceny dostosowane są do różnych rodzajów drzewa i modeli, spełniając oczekiwania zarówno wymagających klientów, jak i tych bardziej zorientowanych na oszczędności.`,
  },
  {
    title: 'Urny',
    description: `Oferujemy szeroki wybór urn wykonanych z różnych materiałów, w różnorodnych wzorach. Ceny są uzależnione od modelu i wykończenia.`,
  },
  {
    title: 'Nagrobki',
    description: `Zapraszamy do zapoznania się z naszym bogatym wyborem nagrobków. Oferujemy ponad 100 różnych rodzajów, a także realizujemy zamówienia indywidualne, dostosowując się do preferencji klienta.`,
  },
  {
    title: 'Kwiaty',
    description: `Oferujemy szeroki wybór kompozycji kwiatowych własnej produkcji, zarówno żywych, jak i sztucznych.`,
  },
  {
    title: 'Kremacja',
    description: `Zapraszamy do skorzystania z naszych usług kremacji. Proces odbywa się zgodnie z normami i z zachowaniem pełnej godności.`,
  },
  {
    title: 'Eksportacja z domu',
    description: `Oferujemy całodobową eksportację ciała zmarłego. Prosimy o kontakt w celu uzgodnienia szczegółów.`,
  },
  {
    title: 'Międzynarodowy transport zwłok',
    description: `Zajmujemy się międzynarodowym transportem zwłok, zapewniając odpowiednie warunki i formalności.`,
  },
  {
    title: 'Samochody',
    description: `Oferujemy pełen zakres usług związanych z przewozem zmarłych, posiadając odpowiednio przystosowane pojazdy.`,
  },
  {
    title: 'Oprawa muzyczna',
    description: `Zapewniamy wysokiej jakości oprawę muzyczną, aby uczcić pamięć zmarłego w sposób piękny i wzruszający.`,
  },
  {
    title: 'ZUS',
    description: `Rozliczamy zasiłek pogrzebowy ZUS, zapewniając profesjonalną obsługę i nie pobierając dodatkowych opłat.`,
  },
  {
    title: 'Akcesoria pogrzebowe',
    description: `Oferujemy odzież żałobną oraz wyjątkowe krzyże ze stali nierdzewnej.`,
  },
];

export default OFFERS_INFO;
