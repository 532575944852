import React from 'react';
import { ThemeProvider } from 'styled-components';

import { About } from '../../components/About/About';
import { Contact } from '../../components/Contact/Contact';
import ContactSidebar from '../../components/ContactSidebar/ContactSidebar';
import { Copyright } from '../../components/Copyright/Copyright';
import { Footer } from '../../components/Footer/Footer';
// import Gallery from '../../components/Gallery/Gallery';
import { Guide } from '../../components/Guide/Guide';
import { Navbar } from '../../components/Navbar/Navbar';
import { Offers } from '../../components/Offers/Offers';
import { Questions } from '../../components/Questions/Questions';
import { Welcome } from '../../components/Welcome/Welcome';
import GlobalStyle from '../../theme/GlobalStyle';
import theme from '../../theme/MainTheme';

function Root() {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Navbar />
        <Welcome />
        <About />
        <Guide />
        <Offers />
        <Contact />
        {/* <Gallery /> */}
        <Questions />
        <Footer />
        <Copyright />
        <ContactSidebar />
        {/* <ContactPhoneButton /> */}
      </ThemeProvider>
    </>
  );
}

export default Root;
