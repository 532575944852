import React from 'react';

import { Text } from '../Text/Text';
import { Wrapper } from './copyright.styles';

function Copyright() {
  return (
    <Wrapper>
      <Text elementName='custom' mobileSize='1rem' mobileWeight='300'>
        Copyright © 2019-2022 Zakład Pogrzebowy ANNA I KRZYSZTOF KOKOT. Wszelkie
        prawa zastrzeżone
      </Text>
    </Wrapper>
  );
}

export { Copyright };
