import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.4rem 1.6rem;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  /* border-radius: 1.6rem; */
  margin-top: 8rem;
  flex-direction: column;
  gap: 2.4rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    scroll-margin-top: 7rem;
    flex-direction: row;
    gap: 8rem;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 4.8rem 3.2rem;
  }
`;

export const AnyQuestions = styled.article`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
  max-width: 24rem;
`;

export const HelpLineNumber = styled.article`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
  max-width: 24rem;
`;
