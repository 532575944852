import React, { useState } from 'react';

import Close from '../../assets/IconComponents/Close';
import HamburgerMenu from '../../assets/IconComponents/HamburgerMenu';
import Link from '../Link/Link';
import {
  Background,
  CloseIconWrapper,
  ContactButton,
  Nav,
  NavCollapse,
  NavContent,
  NavWrapper,
} from './navbar.styles';

const Navbar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      <Nav isCollapsed={isCollapsed}>
        <NavWrapper>
          <NavContent>
            <Link
              elementName='navLink'
              href='#Home'
              onClick={() => setIsCollapsed(false)}
            >
              STRONA GLOWNA
            </Link>

            <Link
              elementName='navLink'
              href='#Offers'
              onClick={() => setIsCollapsed(false)}
            >
              OFERTA
            </Link>

            <Link
              elementName='navLink'
              href='#Guide'
              onClick={() => setIsCollapsed(false)}
            >
              PORADNIK
            </Link>
          </NavContent>
          <NavContent>
            <Link elementName='navLink' href='#About'>
              O NAS
            </Link>

            <ContactButton
              elementName='navLink'
              href='#Contact'
              onClick={() => setIsCollapsed(!isCollapsed)}
              fontColor='#fff'
            >
              KONTAKT
            </ContactButton>
          </NavContent>

          <CloseIconWrapper onClick={() => setIsCollapsed(false)}>
            <Close />
          </CloseIconWrapper>
        </NavWrapper>
      </Nav>

      <Background
        isCollapsed={isCollapsed}
        onClick={() => setIsCollapsed(false)}
      />

      <NavCollapse onClick={() => setIsCollapsed(!isCollapsed)}>
        <HamburgerMenu color='black' />
      </NavCollapse>
    </>
  );
};

export { Navbar };
