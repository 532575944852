import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`


*, *::after, *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}


body {
  margin: 0;
  position: relative;

  @media (min-width: 768px) {
    padding-top: 7rem;
  }


}


::-moz-selection {
  background-color: #FCD9BB;
}

::selection {
  background-color: #FCD9BB;

}




h1,h2,h3,h4,h5,h6,p,span {
  z-index: 3;
  margin: 0;
  padding: 0;
  font-style: normal;
}
`;

export default GlobalStyle;
