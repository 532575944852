import styled from 'styled-components';

export const Wrapper = styled.footer`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 1.6rem;
  background-color: #333;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 1.6rem 3.2rem;
  }
`;

export const Main = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    flex-direction: row;
    gap: 2.4rem;
  }
`;
