import React from 'react';

const PhoneIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.72317 5.50715C9.4108 1.91183 5.66225 0.14772 5.50361 0.0757157C5.35531 0.00595926 5.18911 -0.0163279 5.02769 0.0118936C0.700181 0.730302 0.0492566 3.25046 0.0230887 3.35519C-0.0125137 3.50114 -0.00685112 3.65412 0.0394436 3.79704C5.20105 19.8213 15.9282 22.7915 19.4543 23.7685C19.7258 23.8438 19.9499 23.9043 20.12 23.9599C20.3141 24.0234 20.525 24.0111 20.7104 23.9256C20.8183 23.8765 23.3664 22.6753 23.9895 18.7576C24.0172 18.5866 23.9897 18.4112 23.911 18.2569C23.8554 18.1488 22.5225 15.6107 18.8263 14.7139C18.7009 14.6818 18.5696 14.6807 18.4437 14.7106C18.3178 14.7406 18.201 14.8006 18.1034 14.8857C16.9373 15.8823 15.3264 16.9444 14.6313 17.0541C9.97177 14.7745 7.36971 10.4002 7.27158 9.57049C7.21433 9.1041 8.28231 7.466 9.51056 6.13391C9.58776 6.05008 9.64656 5.951 9.68318 5.84305C9.71979 5.7351 9.73342 5.62068 9.72317 5.50715Z'
      fill='#BA8B33'
    />
  </svg>
);

export default PhoneIcon;
