import styled from 'styled-components';

export const Wrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2.4rem 1.6rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    scroll-margin-top: 7rem;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 4.8rem 3.2rem;
  }
`;

export const OffersWrapper = styled.article`
  display: flex;
  flex-direction: column;
  max-width: 138.5rem;
  gap: 1.6rem;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    gap: 2.4rem;
  }
`;

export const NavigationOffersBar = styled.article`
  z-index: 9;
  border-radius: 0.8rem;
  background-color: #f5f5f5;
  width: 100%;
  margin: 0 auto;
  height: fit-content;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    min-width: 30rem;
    text-align: left;
    position: sticky;
    top: 11rem;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    min-width: 40rem;
  }
`;

export const OffersMain = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    flex-direction: row-reverse;
  }
`;

export const OffersContent = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

export const Offer = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  scroll-margin-top: 10rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    gap: 2.4rem;
  }
`;
