import React from 'react';

import GUIDE_INFO from '../../constants/guide.info';
import { Text } from '../Text/Text';
import {
  Circle,
  GuideContent,
  GuideWrapper,
  InfoWrapper,
  Wrapper,
} from './guide.styles';

function Guide() {
  return (
    <Wrapper id='Guide'>
      <GuideWrapper>
        <Text elementName='componentTitle' align='center'>
          Co potrzebujesz...
        </Text>

        <Text elementName='description' align='center' paddingBottom='0.8'>
          Wychodząc na przeciw Państwu, przygotowaliśmy krótki poradnik
          nawiązujący do załatwiania formalności pogrzebowych.
        </Text>

        <GuideContent>
          {GUIDE_INFO.map(({ number, info }) => (
            <InfoWrapper key={number}>
              <Circle>
                <Text elementName='contactTitle' guideNum>
                  {number}
                </Text>
              </Circle>

              <Text elementName='description'>{info}</Text>
            </InfoWrapper>
          ))}
        </GuideContent>
      </GuideWrapper>
    </Wrapper>
  );
}

export { Guide };
