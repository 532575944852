import React from 'react';

const Close = () => (
  <>
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.8316 12.0082L23.4316 3.42818C23.8082 3.05158 24.0198 2.54079 24.0198 2.00818C24.0198 1.47558 23.8082 0.964791 23.4316 0.588183C23.055 0.211576 22.5442 0 22.0116 0C21.479 0 20.9682 0.211576 20.5916 0.588183L12.0116 9.18818L3.4316 0.588183C3.05499 0.211576 2.5442 -3.9682e-09 2.0116 0C1.47899 3.96821e-09 0.968204 0.211576 0.591597 0.588183C0.214989 0.964791 0.0034132 1.47558 0.0034132 2.00818C0.0034132 2.54079 0.214989 3.05158 0.591597 3.42818L9.1916 12.0082L0.591597 20.5882C0.40414 20.7741 0.255352 20.9953 0.153814 21.239C0.052277 21.4827 0 21.7442 0 22.0082C0 22.2722 0.052277 22.5336 0.153814 22.7773C0.255352 23.0211 0.40414 23.2423 0.591597 23.4282C0.777523 23.6156 0.998725 23.7644 1.24244 23.866C1.48616 23.9675 1.74757 24.0198 2.0116 24.0198C2.27562 24.0198 2.53703 23.9675 2.78075 23.866C3.02447 23.7644 3.24567 23.6156 3.4316 23.4282L12.0116 14.8282L20.5916 23.4282C20.7775 23.6156 20.9987 23.7644 21.2424 23.866C21.4862 23.9675 21.7476 24.0198 22.0116 24.0198C22.2756 24.0198 22.537 23.9675 22.7808 23.866C23.0245 23.7644 23.2457 23.6156 23.4316 23.4282C23.6191 23.2423 23.7678 23.0211 23.8694 22.7773C23.9709 22.5336 24.0232 22.2722 24.0232 22.0082C24.0232 21.7442 23.9709 21.4827 23.8694 21.239C23.7678 20.9953 23.6191 20.7741 23.4316 20.5882L14.8316 12.0082Z'
        fill='black'
      />
    </svg>
  </>
);

export default Close;
