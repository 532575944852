import React from 'react';
import styled from 'styled-components';

import OFFERS_INFO from '../../constants/offers.info';
import Link from '../Link/Link';
import { Text } from '../Text/Text';
import {
  NavigationOffersBar,
  Offer,
  OffersContent,
  OffersMain,
  OffersWrapper,
  Wrapper,
} from './offers.styles';

const OffersLink = styled(Link)`
  padding: 1.2rem 1.6rem;

  border-bottom: solid 0.1rem #ddd;

  &:first-child:hover {
    border-radius: 0.8rem 0.8rem 0 0;
  }

  &:last-child:hover {
    border-radius: 0rem 0 0.8rem 0.8rem;
  }

  &:hover {
    background-color: #eee;
  }

  &:last-child {
    border-bottom: none;
  }
`;

function Offers() {
  return (
    <Wrapper id='Offers'>
      <OffersWrapper>
        <Text elementName='componentTitle' align='center'>
          Oferty
        </Text>

        <OffersMain>
          <NavigationOffersBar>
            {OFFERS_INFO.map(({ title }) => (
              <OffersLink
                key={title}
                elementName='description'
                href={`#${title}`}
              >
                {title}
              </OffersLink>
            ))}
          </NavigationOffersBar>

          <OffersContent>
            {OFFERS_INFO.map(({ title, description }) => (
              <Offer key={title} id={title}>
                <Text elementName='offerTitle'>{title}</Text>
                <Text
                  elementName='description'
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Offer>
            ))}
          </OffersContent>
        </OffersMain>
      </OffersWrapper>
    </Wrapper>
  );
}

export { Offers };
