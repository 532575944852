import React from 'react';

import LocationIcon from '../../assets/IconComponents/Location';
import MaiIcon from '../../assets/IconComponents/Mail';
import PhoneIcon from '../../assets/IconComponents/Phone';
import { Text } from '../Text/Text';
import {
  ContactWrapper,
  ContentWrapper,
  DescriptionWrapper,
  Iframe,
  ImageWrapper,
  TextWrapper,
  Wrapper,
} from './contact.styles';

function Contact() {
  return (
    <Wrapper id='Contact'>
      <ContentWrapper>
        <ContactWrapper>
          <Text elementName='contactTitle'>
            Zakład Pogrzebowy ANNA I KRZYSZTOF KOKOT Jastrzębie-Zdrój
          </Text>

          <TextWrapper>
            <DescriptionWrapper>
              <LocationIcon />

              <Text elementName='description'>
                ul. Podhalańska 29, 44-335 Jastrzębie-Zdrój
              </Text>
            </DescriptionWrapper>

            <DescriptionWrapper>
              <PhoneIcon />

              <Text elementName='description'>32 470 84 32</Text>
            </DescriptionWrapper>

            <DescriptionWrapper>
              <PhoneIcon />

              <Text elementName='description'>32 474 01 44</Text>
            </DescriptionWrapper>

            <DescriptionWrapper>
              <MaiIcon />

              <Text elementName='description'>podhalanska29@gmail.com</Text>
            </DescriptionWrapper>
          </TextWrapper>
        </ContactWrapper>

        <ImageWrapper>
          <Iframe
            title='local location'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1474.816240479878!2d18.597225244621175!3d49.940011407425885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471150466217d0cf%3A0xc602d486dac98eeb!2sKompleksowe%20Us%C5%82ugi%20Pogrzebowe%20Kokot%20A.K.!5e0!3m2!1spl!2spl!4v1660513478999!5m2!1spl!2spl'
            allowfullscreen=''
            loading='lazy'
            referrerpolicy='no-referrer-when-downgrade'
          ></Iframe>
        </ImageWrapper>
      </ContentWrapper>
    </Wrapper>
  );
}

export { Contact };
