import React from "react";
import styled from "styled-components";

import ceremony from "../../assets/images/ceremonia.jpg";
import logo from "../../assets/images/logo.png";
import { Text } from "../Text/Text";
import {
  AboutContent,
  AboutContentWrapper,
  AboutMain,
  Image,
  ImageWrapper,
  Wrapper,
} from "./about.styles";

const AboutTextDescription = styled(Text)`
  line-height: 150%;
  width: 90%;
`;

function About() {
  return (
    <Wrapper id="About">
      <Text elementName="componentTitle" align="center">
        Kim jesteśmy?
      </Text>

      <AboutMain>
        <ImageWrapper>
          <Image src={logo} alt="" />
        </ImageWrapper>

        <AboutContentWrapper>
          <AboutContent>
            <AboutTextDescription elementName="description">
              Pogotowie Pogrzebowe <b>Anna i Krzysztof Kokot</b> – Profesjonalne
              wsparcie w trudnych chwilach
            </AboutTextDescription>

            <AboutTextDescription elementName="description">
              Jesteśmy rodziną, która od <b>1997</b> roku wspiera inne rodziny w
              Jastrzębiu-Zdroju i okolicach, oferując profesjonalne usługi
              związane z organizacją ceremonii. Rozumiemy, jak ważne jest
              odpowiednie wsparcie w tych chwilach, dlatego każde zlecenie
              traktujemy z <b>indywidualnym podejściem i empatią.</b>
            </AboutTextDescription>
          </AboutContent>
        </AboutContentWrapper>
      </AboutMain>

      <AboutMain>
        <ImageWrapper>
          <Image src={ceremony} alt="" />
        </ImageWrapper>

        <AboutContentWrapper>
          <AboutContent>
            <AboutTextDescription elementName="description">
              Oferujemy wsparcie w załatwianiu wszelkich formalności oraz{" "}
              <b>doradztwo w wyborze odpowiednich usług</b>, które najlepiej
              oddadzą hołd życiu bliskich.
            </AboutTextDescription>

            <AboutTextDescription elementName="description">
              Usługi dostosowane do są indywidualnych potrzeb:
              <p>
                - Organizacja ceremonii w różnych tradycjach kulturowych oraz
                świeckich.
              </p>
              <p>
                - Szeroki wybór opcji personalizacji, od wyboru elementów
                ceremonii po aranżacje kwiatowe.
              </p>
              <p>
                - Profesjonalne doradztwo i wsparcie w każdym etapie
                przygotowań.
              </p>
            </AboutTextDescription>

            <AboutTextDescription elementName="description">
              Zachęcamy do kontaktu, aby omówić, jak możemy pomóc w
              przygotowaniu odpowiedniej ceremonii dla Twoich bliskich.
            </AboutTextDescription>
          </AboutContent>
        </AboutContentWrapper>
      </AboutMain>
    </Wrapper>
  );
}

export { About };
