const theme = {
  font: {
    phone: {
      size: {
        componentTitle: '3.2rem',
        offerTitle: '2.4rem',
        homeTitle: '1.6rem',
        contactTitle: '2.4rem',
        description: '1.8rem',
        navLink: '2rem',
        button: '1.6rem',
      },
    },
    desktop: {
      size: {
        componentTitle: '4rem',
        offerTitle: '3rem',
        homeTitle: '2.4rem',
        contactTitle: '2.6rem',
        description: '1.8rem',
        navLink: '2rem',
        button: '2rem',
      },
    },
  },
  colors: {
    black: '#222222',
    golden: '#E5B250',
    grey: '#DDDDDD',
    button: '#BA8B33',
    // button: '#73233F',
    accent: '#BA8B33',
    // secondaryBackground: '#FEF1F6',
    secondaryBackground: '#F7E3BE',
    // secondaryBackground: '#F2D399',
  },
  breakpoints: {
    tablet: '(min-width: 768px)',
    desktop: '(min-width: 1024px)',
  },
};

export default theme;
