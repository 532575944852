const MaiIcon = () => (
  <svg
    width='26'
    height='26'
    viewBox='0 0 26 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13 12.0331L23.3837 5.3625C22.9662 5.04937 22.4593 4.87849 21.9375 4.875H4.06246C3.54062 4.87849 3.03368 5.04937 2.61621 5.3625L13 12.0331Z'
      fill='#BA8B33'
    />
    <path
      d='M13.4388 13.6825L13.3006 13.7475H13.2356C13.1608 13.7809 13.0814 13.8028 13 13.8125C12.9326 13.821 12.8643 13.821 12.7969 13.8125H12.7319L12.5938 13.7475L1.70625 6.71124C1.65423 6.90749 1.62694 7.10947 1.625 7.31249V18.6875C1.625 19.334 1.88181 19.9539 2.33893 20.4111C2.79605 20.8682 3.41603 21.125 4.0625 21.125H21.9375C22.584 21.125 23.204 20.8682 23.6611 20.4111C24.1182 19.9539 24.375 19.334 24.375 18.6875V7.31249C24.3731 7.10947 24.3458 6.90749 24.2937 6.71124L13.4388 13.6825Z'
      fill='#BA8B33'
    />
  </svg>
);

export default MaiIcon;
