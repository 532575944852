import React from 'react';

// import HomePhoneIcon from '../../assets/IconComponents/HomePhoneIcon';
import video from '../../assets/video/video-compressed.mp4';
// import { Text } from '../Text/Text';
import {
  // BottomBorder,
  // Content,
  // InfoWrapper,
  // NumberWrapper,
  // PhoneUsLink,
  Video,
  Wrapper,
} from './welcome.styles';

function Welcome() {
  return (
    <Wrapper className='wrapper' id='Home'>
      {/* <Content>
        <InfoWrapper>
          <Text elementName='homeTitle' align='center' fontColor='#fff'>
            PRACUJEMY 24H, 7 DNI W TYGODNIU. PRZYJEDZIEMY NAJSZYBCIEJ JAK TO
            MOZLIWE!
          </Text>
          <NumberWrapper>
            <HomePhoneIcon />
            <Text elementName='homeTitle' align='center' fontColor='#fff'>
              502 521 939
            </Text>
          </NumberWrapper>
        </InfoWrapper>
        <PhoneUsLink
          elementName='button'
          href='tel:502 521 939'
          fontColor='#fff'
        >
          ZGŁOŚ POGRZEB
        </PhoneUsLink>
        <BottomBorder />
      </Content> */}
      <Video src={video} width='1385' type='video/mp4' autoPlay muted loop />
    </Wrapper>
  );
}

export { Welcome };
