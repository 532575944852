import styled from 'styled-components';

export const Wrapper = styled.section`
  padding: 2.4rem 1.6rem;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};

  @media ${({ theme }) => theme.breakpoints.tablet} {
    scroll-margin-top: 7rem;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 4.8rem 3.2rem;
  }
`;

export const GuideWrapper = styled.article`
  display: flex;
  flex-direction: column;
  max-width: 110rem;
  margin: 0 auto;
  gap: 1.6rem;
`;

export const GuideContent = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.6rem;

  ::before {
    content: '';
    position: absolute;
    background-color: #000;
    top: 0;
    border: 0.3rem solid black;
    height: 100%;
    left: 1.6rem;
    transform: translateX(-50%);
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 1.6rem 0;
    gap: 2.4rem;
  }
`;

export const Circle = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  justify-content: center;
  align-items: center;
  border: 0.2rem solid black;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  aspect-ratio: 1;
  z-index: 2;
`;

export const InfoWrapper = styled.article`
  display: flex;
  align-items: center;
  gap: 2.4rem;
`;
