import React from 'react';

import Link from '../Link/Link';
import { Main, Wrapper } from './footer.styles';

function Footer() {
  return (
    <Wrapper>
      <Main>
        <Link elementName='footer' href='#Home'>
          Strona główna
        </Link>
        <Link elementName='footer' href='#Guide'>
          Poradnik
        </Link>
        <Link elementName='footer' href='#Contact'>
          Kontakt
        </Link>
        <Link elementName='footer' href='#Offers'>
          Oferta
        </Link>
        <Link elementName='footer' href='#About'>
          O nas
        </Link>
      </Main>
    </Wrapper>
  );
}

export { Footer };
