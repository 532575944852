import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1.6rem;
  background-color: #fff;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 1.6rem 3.2rem;
  }
`;
