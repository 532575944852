import styled from 'styled-components';

export const Wrapper = styled.section`
  padding: 2.4rem 1.6rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    scroll-margin-top: 7rem;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 3.6rem 3.2rem;
  }
`;

export const ContentWrapper = styled.article`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2.4rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    margin: auto;
    max-width: 138.5rem;
    flex-direction: row;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    gap: 4.8rem;
  }
`;

export const ContactWrapper = styled.article`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    width: 50%;
  }

  @media ${({ theme }) => theme.breakpoints.tablet} {
    padding-top: 1.6rem;
  }
`;

export const ImageWrapper = styled.article`
  @media ${({ theme }) => theme.breakpoints.tablet} {
    width: 50%;
  }
`;

export const TextWrapper = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const DescriptionWrapper = styled.article`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;

export const ImageMap = styled.img`
  border-radius: 0.8rem;
  object-fit: cover;
  width: 100%;
  height: 25rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    height: 30rem;
  }
`;

export const Iframe = styled.iframe`
  border: 0;
  border-radius: 0.8rem;
  object-fit: cover;
  width: 100%;
  height: 32rem;
`;
