import styled from 'styled-components';

// import { Link } from '../Link/Link';

export const Wrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  /* object-fit: cover; */
  height: 100vh;
  max-height: 30rem;

  padding: 2.4rem 1.6rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    max-height: 35rem;
    scroll-margin-top: 7rem;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    max-height: 40rem;

    padding: 4.8rem 3.2rem;
  }

  /* ::before {
    content: '';
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.45;
    z-index: 8;
  } */
`;

export const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  /* height: calc(100% + 4.7rem); */

  object-fit: cover;
  max-width: 100%;
  /* border-radius: 0.4rem; */

  @media ${({ theme }) => theme.breakpoints.tablet} {
    max-height: 35rem;
    grid-column: 1/3;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    max-height: 40rem;
    grid-column: 1/3;
  }
`;

// export const Image = styled.img`
//   position: absolute;
//   width: 100%;
//   object-fit: cover;
//   height: 100%;
//   /* height: calc(100% + 4.7rem);
//   top: -4.7rem; */

//   @media ${({ theme }) => theme.breakpoints.tablet} {
//     /* height: calc(100% + 20rem);
//     top: -20rem; */
//   }
// `;

// export const BottomBorder = styled.div`
//   display: none;

//   @media ${({ theme }) => theme.breakpoints.tablet} {
//     display: block;

//     ::before {
//       content: '';
//       position: absolute;
//       width: 30%;
//       height: 0.2rem;
//       background-color: #fff;
//       bottom: 0;
//       right: 0;
//       border-radius: 0.8rem 0 0 0.8rem;
//     }

//     ::after {
//       content: '';
//       position: absolute;
//       width: 30%;
//       height: 0.2rem;
//       background-color: #fff;
//       bottom: 0;
//       left: 0;
//       border-radius: 0 0.8rem 0.8rem 0;
//     }
//   }
// `;

// export const InfoWrapper = styled.article`
//   display: flex;
//   flex-direction: column;
//   gap: 0.8rem;
// `;

// export const Content = styled.article`
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   gap: 1.6rem;
//   width: 90rem;
//   z-index: 9;

//   @media ${({ theme }) => theme.breakpoints.tablet} {
//     padding: 6.5rem 10rem;
//     border-left: solid 0.2rem #fff;
//     border-right: solid 0.2rem #fff;

//     ::after {
//       content: '';
//       position: absolute;
//       top: 0;
//       left: 0;
//       height: 0.2rem;
//       width: 45%;
//       background-color: #fff;
//       border-radius: 0 0.8rem 0.8rem 0;
//     }

//     ::before {
//       content: '';
//       position: absolute;
//       top: 0;
//       right: 0;
//       height: 0.2rem;
//       width: 45%;
//       background-color: #fff;
//       border-radius: 0.8rem 0 0 0.8rem;
//     }
//   }
// `;

// export const NumberWrapper = styled.article`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 1.6rem;
// `;

// export const PhoneUsLink = styled(Link)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: ${({ theme }) => theme.colors.button};
//   padding: 1.2rem 1.6rem;
//   border-radius: 0.8rem;
//   width: fit-content;

//   @media ${({ theme }) => theme.breakpoints.tablet} {
//     position: absolute;
//     bottom: 0;
//     left: 50%;
//     transform: translateX(-50%) translateY(50%);
//     max-width: 30%;
//     width: 100%;
//   }
// `;
