import React from 'react';

const HamburgerMenu = ({ color }) => (
  <>
    <svg
      width='32'
      height='26'
      viewBox='0 0 38 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M36.0208 12.8448H2.47917C1.3861 12.8448 0.5 13.9109 0.5 15.226V15.4767C0.5 16.7918 1.3861 17.8579 2.47917 17.8579H36.0208C37.1139 17.8579 38 16.7918 38 15.4767V15.226C38 13.9109 37.1139 12.8448 36.0208 12.8448Z'
        fill={color}
      />
      <path
        d='M36.0208 25.3774H2.47917C1.3861 25.3774 0.5 26.4435 0.5 27.7586V28.0093C0.5 29.3244 1.3861 30.3904 2.47917 30.3904H36.0208C37.1139 30.3904 38 29.3244 38 28.0093V27.7586C38 26.4435 37.1139 25.3774 36.0208 25.3774Z'
        fill={color}
      />
      <path
        d='M36.0208 0.312378H2.47917C1.3861 0.312378 0.5 1.37847 0.5 2.69355V2.9442C0.5 4.25929 1.3861 5.32538 2.47917 5.32538H36.0208C37.1139 5.32538 38 4.25929 38 2.9442V2.69355C38 1.37847 37.1139 0.312378 36.0208 0.312378Z'
        fill={color}
      />
    </svg>
  </>
);

export default HamburgerMenu;
