const GUIDE_INFO = [
  {
    number: '1',
    info: 'Lekarz, który stwierdza zgon np. lekarz pogotowia, wystawia kartę informacyjną lub kartę zgonu proszę nie mylić z aktem zgonu, wydawanym przez Urząd Stanu Cywilnego.',
  },
  {
    number: '2',
    info: 'Karta zgonu jest dokumentem stwierdzającym zgon.',
  },
  {
    number: '3',
    info: 'W pierwszej kolejności należy zadbać o zabranie zwłok przez zakład.',
  },
  {
    number: '4',
    info: 'Po uzgodnieniu zakresu usług, większość czynności związanych z pochówkiem zmarłego może przejąć zakład pogrzebowy.',
  },
  {
    number: '5',
    info: 'Urząd Stanu Cywilnego, właściwy ze względu na miejsce zgonu, na podstawie dowodu osobistego zmarłego, Państwa dowodu osobistego i karty zgonu, wyda akt zgonu.',
  },
  {
    number: '6',
    info: 'Posiadając kartę zgonu i akt zgonu, można następnie udać się do kancelarii parafialnej i ustalić datę i godzinę pogrzebu.',
  },
  {
    number: '7',
    info: 'Jeżeli większość spraw załatwialiśmy sami - dostarczamy rachunki związane z pogrzebem oraz akt zgonu do odpowiedniego zakładu ubezpieczeniowego, w którym zmarły był ubezpieczony (ZUS, KRUS) i pobieramy zasiłek pogrzebowy.',
  },
];

export default GUIDE_INFO;
