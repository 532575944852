import styled from 'styled-components';

import Link from '../Link/Link';

export const Nav = styled.nav`
  position: fixed;
  width: 100vw;
  z-index: 99;
  padding: 8.8rem 3.2rem 8.8rem 3.2rem;
  background-color: #fff;
  box-shadow: 0 0.3rem 0.4rem -0.2rem rgba(0, 0, 0, 0.2);
  /* height: 40; */
  top: ${({ isCollapsed }) => (isCollapsed ? '0' : '-80%')};
  transition: top 600ms ease;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    height: 7rem;
    top: 0;
    padding: 0 1.6rem;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 0 3.2rem;
  }
`;

export const NavContent = styled.article`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2.4rem;
  height: 100%;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    align-items: center;
    width: fit-content;
    flex-direction: row;
    gap: 2rem;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    gap: 4.8rem;
  }
`;

export const NavWrapper = styled.article`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  width: fit-content;
  gap: 2.4rem;
  z-index: 99;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    height: 100%;
    width: 100%;
    max-width: 138.5rem;
    justify-content: space-between;
    flex-direction: row;
    position: static;
    display: flex;
  }
`;

export const NavCollapse = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 3.2rem;
  right: 3.2rem;
  width: fit-content;
  height: fit-content;
  display: flex;
  z-index: 98;
  background-color: #fff;
  padding: 0.8rem;
  border-radius: 0.4rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    display: none;
  }
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: ${({ isCollapsed }) => (isCollapsed ? '0' : '-100%')};
  background-color: #000;
  height: 100vh;
  width: 100vw;
  z-index: 98;
  opacity: ${({ isCollapsed }) => (isCollapsed ? '0.2' : '0')};
  transition: opacity 600ms ease;
  @media ${({ theme }) => theme.breakpoints.tablet} {
    display: none;
  }
`;

export const ContactButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.button};
  padding: 1.6rem 0;
  width: 100%;
  border-radius: 0.4rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    width: fit-content;
    border-radius: 0;
    height: 100%;
    padding: 0 2.4rem;
  }
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  position: absolute;
  height: fit-content;
  width: fit-content;
  top: 3.2rem;
  right: 3.2rem;
  @media ${({ theme }) => theme.breakpoints.tablet} {
    display: none;
  }
`;
