import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 4rem;
  padding: 2.4rem 1.6rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    scroll-margin-top: 7rem;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 4.8rem 3.2rem 8rem 3.2rem;
    max-width: 1300px;
    margin: 0 auto;
  }
`;

export const AboutMain = styled.article`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  max-width: 60rem;
  gap: 3.6rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    max-width: 138.5rem;
    flex-direction: row;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    gap: 6rem;
    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
  }
`;

export const AboutContentWrapper = styled.article`
  @media ${({ theme }) => theme.breakpoints.tablet} {
    padding: 1.6rem 0;
    width: ${({ oneElement }) => (oneElement ? "100%" : "50%")};
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: ${({ oneElement }) => (oneElement ? "100%" : "55%")};
  }
`;

export const AboutContent = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3.2rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    height: 100%;
  }
`;

export const ImageWrapper = styled.article`
  @media ${({ theme }) => theme.breakpoints.tablet} {
    min-width: 40rem;
    width: 50%;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 50%;
    min-width: 46rem;
    /* max-height: 42rem; */
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  max-height: 55rem;
  object-fit: cover;
  border-radius: 0.8rem;
`;
