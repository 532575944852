import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '../Text/Text';
import { Wrapper } from './link.styles';

const Link = ({
  children,
  paddingH,
  paddingV,
  elementName,
  backgroundColor,
  borderBetween,
  btnHeight,
  fontColor,
  ...restProps
}) => {
  return (
    <Wrapper
      borderBetween={borderBetween}
      backgroundColor={backgroundColor}
      paddingH={paddingH}
      paddingV={paddingV}
      btnHeight={btnHeight}
      fontColor={fontColor}
      {...restProps}
    >
      <Text fontColor={fontColor} elementName={elementName}>
        {children}
      </Text>
    </Wrapper>
  );
};

Link.propTypes = {
  children: PropTypes.string,
};

Link.defaultProps = {
  elementName: 'buttonText',
};

export default Link;
