import PropTypes from 'prop-types';
import React from 'react';

import {
  Button,
  ComponentTitle,
  ContactTitle,
  Custom,
  Description,
  Footer,
  HomeTitle,
  NavLink,
  OfferTitle,
} from './text.styles';

const Text = ({ elementName, children, ...restProps }) => {
  const componentsMap = {
    componentTitle: ComponentTitle,
    offerTitle: OfferTitle,
    homeTitle: HomeTitle,
    contactTitle: ContactTitle,
    description: Description,
    navLink: NavLink,
    button: Button,
    custom: Custom,
    footer: Footer,
  };

  const Component = componentsMap[elementName];

  return <Component {...restProps}>{children}</Component>;
};

Text.propTypes = {
  elementName: PropTypes.oneOf([
    'componentTitle',
    'offerTitle',
    'homeTitle',
    'contactTitle',
    'description',
    'navLink',
    'button',
    'custom',
    'footer',
  ]),
  children: PropTypes.any,
};

Text.defaultProps = {
  elementName: 'description',
};

export { Text };
