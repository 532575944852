import styled, { css } from 'styled-components';

export const Wrapper = styled.a`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'transparent'};

  height: ${({ btnHeight }) => (btnHeight ? btnHeight : 'fit-content')};

  padding-top: ${({ paddingV }) => (paddingV ? `${paddingV}` : `0`)};

  padding-bottom: ${({ paddingV }) => (paddingV ? `${paddingV}` : `0`)};

  padding-inline: ${({ paddingH }) => (paddingH ? `${paddingH}` : `0`)};

  ${({ borderBetween }) =>
    borderBetween &&
    css`
      border-bottom: ${({ borderBetween }) =>
        borderBetween ? `solid 0.1rem ${borderBetween}` : 'solid 0.1rem #000'};

      &:last-child {
        border-bottom: none;
      }
    `};
`;
