import React, { useState } from 'react';
import { useMedia } from 'react-use';
import styled from 'styled-components';

import Close from '../../assets/IconComponents/Close';
import ContactPhoneIconSvg from '../../assets/IconComponents/ContactPhoneIcon';
import LocationIcon from '../../assets/IconComponents/Location';
import MaiIcon from '../../assets/IconComponents/Mail';
import PhoneIcon from '../../assets/IconComponents/Phone';
import { Text } from '../Text/Text';

export const Wrapper = styled.section`
  position: fixed;
  padding: 3.6rem 3.6rem;
  width: 100%;
  height: fit-content;
  background-color: #fff;
  z-index: 99;
  bottom: 0;
  transform: translateY(${({ isCollapsed }) => (isCollapsed ? '0' : '100%')});
  border-radius: 0.8rem 0 0 0.8rem;
  box-shadow: 0px -6px 16px rgba(0, 0, 0, 0.1);
  transition: transform 400ms ease-out;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    right: 0;
    transform: translateX(${({ isCollapsed }) => (isCollapsed ? '0' : '100%')})
      translateY(-50%);
    max-width: 34rem;
    padding: 2.4rem;
    top: calc(7rem + (35rem / 2));
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    top: calc(7rem + (40rem / 2));
  }
`;

export const ContentWrapper = styled.article`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    margin: auto;
    max-width: 138.5rem;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
  }
`;

export const DescriptionWrapper = styled.article`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;

export const PhoneWrapper = styled.div`
  display: flex;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.colors.button};
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
  z-index: 98;
  cursor: pointer;
`;

const PhoneLink = styled.a`
  display: flex;
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  height: fit-content;
  z-index: 99;
  cursor: pointer;
  width: 2.4rem;
  @media ${({ theme }) => theme.breakpoints.tablet} {
    width: 2.4rem;
  }
`;

const ContactSidebarTitle = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
`;

const LocationIconn = styled.div`
  height: 2.4rem;
`;

const ContactSidebar = () => {
  const isMobile = useMedia('(max-width: 768px)');
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      <Wrapper isCollapsed={isCollapsed}>
        <ContentWrapper>
          <ContactSidebarTitle>
            <Text elementName='contactTitle'>Kontakt</Text>

            <CloseIconWrapper onClick={() => setIsCollapsed(false)}>
              <Close />
            </CloseIconWrapper>
          </ContactSidebarTitle>

          <DescriptionWrapper>
            <LocationIconn>
              <LocationIcon />
            </LocationIconn>
            <Text elementName='description'>
              ul. Podhalańska 29, 44-335 Jastrzębie-Zdrój
            </Text>
          </DescriptionWrapper>

          <DescriptionWrapper>
            <PhoneIcon />

            <Text elementName='description'>32 470 84 32</Text>
          </DescriptionWrapper>

          <DescriptionWrapper>
            <PhoneIcon />

            <Text elementName='description'>32 474 01 44</Text>
          </DescriptionWrapper>

          <DescriptionWrapper>
            <MaiIcon />

            <Text elementName='description'>podhalanska29@gmail.com</Text>
          </DescriptionWrapper>
        </ContentWrapper>
      </Wrapper>
      <PhoneWrapper
        onClick={() => (!isMobile ? setIsCollapsed(!isCollapsed) : null)}
      >
        <PhoneLink
          elementName='description'
          {...(isMobile && { href: 'tel:32 470 84 32' })}
        >
          <ContactPhoneIconSvg />
        </PhoneLink>
      </PhoneWrapper>
    </>
  );
};

export default ContactSidebar;
