import styled from 'styled-components';

export const ComponentTitle = styled('div')`
  font-family: 'Playfair Display';
  font-weight: 500;

  font-size: ${({ theme }) => theme.font.phone.size.componentTitle};

  color: ${({ fontColor }) => (fontColor ? fontColor : '#262626')};

  ${({ align }) => (align ? `text-align: ${align};` : '')}

  ${({ paddingBottom }) =>
    paddingBottom ? `padding-bottom: ${paddingBottom}rem;` : ''}


  @media ${({ theme }) => theme.breakpoints.tablet} {
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: ${({ theme }) => theme.font.desktop.size.componentTitle};
  }
`;

export const OfferTitle = styled.h2`
  font-family: 'Playfair Display';
  font-weight: 500;

  font-size: ${({ theme }) => theme.font.phone.size.offerTitle};

  color: ${({ fontColor }) => (fontColor ? fontColor : '#262626')};

  ${({ align }) => (align ? `text-align: ${align};` : '')}

  ${({ paddingBottom }) =>
    paddingBottom ? `padding-bottom: ${paddingBottom}rem;` : ''}


  @media ${({ theme }) => theme.breakpoints.tablet} {
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-weight: 500;
    font-size: ${({ theme }) => theme.font.desktop.size.offerTitle};
  }
`;

export const HomeTitle = styled.h3`
  font-family: 'Nunito';
  font-weight: 700;

  font-size: ${({ theme }) => theme.font.phone.size.homeTitle};

  color: ${({ fontColor }) => (fontColor ? fontColor : '#262626')};

  ${({ align }) => (align ? `text-align: ${align};` : '')}

  ${({ paddingBottom }) =>
    paddingBottom ? `padding-bottom: ${paddingBottom}rem;` : ''}


  @media ${({ theme }) => theme.breakpoints.tablet} {
    font-weight: 700;
    font-size: ${({ theme }) => theme.font.desktop.size.homeTitle};
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
  }
`;

export const ContactTitle = styled.h4`
  font-family: 'Nunito';
  font-weight: 500;

  font-size: ${({ theme }) => theme.font.phone.size.contactTitle};

  color: ${({ fontColor }) => (fontColor ? fontColor : '#262626')};

  ${({ align }) => (align ? `text-align: ${align};` : '')}

  ${({ paddingBottom }) =>
    paddingBottom ? `padding-bottom: ${paddingBottom}rem;` : ''}


  @media ${({ theme }) => theme.breakpoints.tablet} {
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-weight: 400;
    font-size: ${({ theme, guideNum }) =>
      guideNum ? '2.2rem' : theme.font.desktop.size.contactTitle};
  }
`;

export const Description = styled.p`
  font-family: 'Nunito';
  font-weight: 300;
  line-height: 150%;
  font-size: ${({ theme }) => theme.font.phone.size.description};
  color: ${({ fontColor }) => (fontColor ? fontColor : '#262626')};
  ${({ align }) => (align ? `text-align: ${align};` : '')}
  ${({ paddingBottom }) =>
    paddingBottom ? `padding-bottom: ${paddingBottom}rem;` : ''}

  @media ${({ theme }) => theme.breakpoints.tablet} {
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-weight: 300;
    font-size: ${({ theme }) => theme.font.desktop.size.description};
  }
`;

export const NavLink = styled.span`
  font-family: 'Nunito';
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.phone.size.navLink};
  color: ${({ fontColor }) => (fontColor ? fontColor : '#262626')};
  ${({ align }) => (align ? `text-align: ${align};` : '')}
  ${({ paddingBottom }) =>
    paddingBottom ? `padding-bottom: ${paddingBottom}rem;` : ''}

  @media ${({ theme }) => theme.breakpoints.tablet} {
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-weight: 500;
    font-size: ${({ theme }) => theme.font.desktop.size.navLink};
  }
`;

export const Button = styled.h5`
  font-family: 'Nunito';
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.phone.size.button};
  color: ${({ fontColor }) => (fontColor ? fontColor : '#262626')};
  ${({ align }) => (align ? `text-align: ${align};` : '')}
  ${({ paddingBottom }) =>
    paddingBottom ? `padding-bottom: ${paddingBottom}rem;` : ''}

  @media ${({ theme }) => theme.breakpoints.tablet} {
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-weight: 500;
    font-size: ${({ theme }) => theme.font.desktop.size.button};
  }
`;

export const Custom = styled.h5`
  font-family: 'Nunito';
  font-size: ${({ mobileSize }) => (mobileSize ? mobileSize : null)};
  font-weight: ${({ mobileWeight }) => (mobileWeight ? mobileWeight : null)};
  color: ${({ fontColor }) => (fontColor ? fontColor : '#262626')};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-weight: ${({ desktopWeight }) =>
      desktopWeight ? desktopWeight : null};
    font-size: ${({ desktopSize }) => (desktopSize ? desktopSize : null)};
  }
`;

export const Footer = styled.h5`
  font-family: 'Nunito';
  font-size: 1.6rem;
  font-weight: 300;
  color: #fff;
`;
