import React from 'react';

import QuestionIcon from '../../assets/IconComponents/Question';
import RingingPhoneIcon from '../../assets/IconComponents/RingingPhone';
import { Text } from '../Text/Text';
import { AnyQuestions, HelpLineNumber, Wrapper } from './questions.styles';

function Questions() {
  return (
    <Wrapper>
      <AnyQuestions>
        <QuestionIcon />

        <Text elementName='contactTitle' align='center'>
          Masz pytania?
        </Text>

        <Text elementName='description' align='center'>
          Skontaktuj się z naszym działem pomocy
        </Text>
      </AnyQuestions>

      <HelpLineNumber>
        <RingingPhoneIcon />

        <Text elementName='contactTitle' align='center'>
          Całodobowa infolinia
        </Text>

        <Text elementName='description' align='center'>
          32 470 84 32
        </Text>

        <Text elementName='description' align='center'>
          32 474 01 44
        </Text>
      </HelpLineNumber>
    </Wrapper>
  );
}

export { Questions };
