import React from 'react';

const LocationIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.4432 20.6467C21.4432 22.2916 17.2153 23.625 11.9999 23.625C6.78453 23.625 2.55664 22.2916 2.55664 20.6467C2.55664 19.3545 5.16598 18.2548 8.81328 17.8424C9.25034 18.4301 9.67686 18.9856 10.0742 19.4943C8.68639 19.6915 7.73516 20.0952 7.73516 20.5613C7.73516 21.2215 9.64456 21.7568 11.9999 21.7568C14.3553 21.7568 16.2647 21.2215 16.2647 20.5613C16.2647 20.0956 15.3152 19.6922 13.9294 19.4948C14.329 18.9854 14.7573 18.4302 15.1956 17.8434C18.8382 18.2565 21.4432 19.3556 21.4432 20.6467ZM11.9999 0.375C7.87437 0.375 4.52994 3.71944 4.52994 7.845C4.52994 11.2734 9.50961 17.6051 11.8172 20.4856C11.8631 20.543 11.9263 20.5733 11.9999 20.5733C12.0733 20.5734 12.1365 20.5431 12.1826 20.4859C14.3946 17.7294 19.4699 11.3676 19.4699 7.845C19.4699 3.71944 16.1255 0.375 11.9999 0.375ZM11.9999 3.92175C14.1616 3.92175 15.9141 5.67422 15.9141 7.83591C15.9141 9.99764 14.1617 11.7501 11.9999 11.7501C9.8382 11.7501 8.08578 9.99764 8.08578 7.83591C8.08578 5.67422 9.8382 3.92175 11.9999 3.92175Z'
      fill='#BA8B33'
    />
  </svg>
);

export default LocationIcon;
